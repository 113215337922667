@import "../../const/settings.scss";
.banner-top {
  height: 622px;
  width: 100%;
  background-image: url("../../../public/image/banner_backgr.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
  position: relative;
  .banner-slider-mobile,
  .img-decor-above,
  .img-decor-below {
    display: none;
  }
  .wp-banner-for-css {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .wp-banner-content {
    height: 100%;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    .banner-content {
      width: 46%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title {
        color: var(--Color-3, #fff);
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 16px;
      }
      .title {
        font-size: 36px;
      }
      .wp-statistics {
        display: flex;
        justify-content: flex-start;
        .item-statistics {
          position: relative;
          .statistics-img {
            width: 100%;
            object-position: center;
          }
          .content {
            position: absolute;
            top: 16px;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding: 0 16px;
            .statistics,
            .key {
              text-align: center;
              font-style: normal;
              font-weight: 600;
              line-height: 130%;
              text-transform: capitalize;
              position: relative;
            }
            .statistics {
              color: #ffd37c;
              font-size: 24px;

              .layer-statistics {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                color: #cd9a4b;
                -webkit-text-stroke-width: 0px;
              }
            }
            .key {
              color: #ffd37c;
              font-size: 14px;
              font-weight: 500;
              line-height: 150%;

              .layer-key {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-text-stroke-width: 0px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .banner-search {
      display: flex;
      margin-top: 40px;
      z-index: 9;
      max-width: 308px;
    }
  }
  .wp-banner-image {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    .image-women {
      width: auto;
      height: auto;
      margin-right: -119px;
    }
  }
}

@media only screen and (min-width: 1550px) {
  .banner-top {
    height: calc(100vh - 150px);
    max-height: 800px !important;
    .wp-banner-image {
      .image-women {
        margin-right: -159px !important;
      }
    }
  }
}
@media only screen and (min-width: 1920px) {
  .banner-top {
    min-height: auto;
    height: 800px !important;
    max-height: none;
    .wp-banner-image {
      .image-women {
        margin-right: -259px !important;
      }
    }
  }
}

@include media(lg) {
  .wp-banner-for-css {
    position: static !important;
    width: 100%;
    height: 100%;
  }
  .for-banner-image {
    display: none;
  }
  .banner-top {
    min-height: auto;
    height: 100%;
    background: linear-gradient(195deg, #2a3467 -0.19%, #00c5d7 125.83%);
    .banner-content {
      margin-left: 0px !important;
      align-items: center !important;
      .title {
        text-align: center !important;
      }
    }
    .banner-slider-desktop {
      display: none !important;
    }
    .wp-banner-content {
      position: static;
      justify-content: flex-start;
      .banner-content {
        width: 100%;
        padding: 24px 15px;
        .wp-statistics {
          justify-content: center;
        }
      }
      .banner-search {
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: 100%;
        padding: 0px 15px;
      }
    }
    .banner-slider-mobile {
      display: block;
      height: 100% !important;
      width: 100% !important;
      z-index: 1;
      .home-banner-img {
        height: 450px;
        width: 100%;
        object-position: top !important;
        object-fit: cover !important;
      }
    }
    .banner-search {
      margin-left: 0 !important;
      justify-content: center !important;
    }
  }
}

@include media(md) {
  .banner-top {
    min-height: auto;
    height: 100%;
    .banner-content {
      margin-left: 0 !important;
    }
    .banner-slider-desktop {
      display: none !important;
    }
    .wp-banner-content {
      position: static;
      justify-content: flex-start;
      .banner-content {
        width: 100%;
        padding: 24px 15px;
        .title {
          font-size: 20px !important;
          margin-bottom: 8px !important;
        }
        .wp-statistics {
          margin-bottom: 14px;
          .statistics-img {
            height: 100%;
          }
          .content {
            top: 0px !important;
            padding: 0 12px !important;
          }
          .statistics {
            font-size: 14px !important;
            .layer-statistics {
              font-size: 14px !important;
            }
          }
          .key {
            font-size: 12px !important;
            .layer-key {
              font-size: 12px !important;
            }
          }
        }
      }
      .banner-search {
        margin-top: 20px;
      }
    }
    .banner-slider-mobile {
      display: block;
      height: 100% !important;
      width: 100% !important;
      .home-banner-img {
        height: 289px;
        width: 100%;
        object-fit: cover !important;
      }
    }
    .img-decor-above {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: auto;
      width: auto;
    }
    .img-decor-below {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
    }
  }
}

@include media(ssm_414) {
  .banner-top {
    // height: 700px;
    height: 100%;
    min-height: auto;
    .wp-banner-content {
      position: static;
      justify-content: flex-start;
      .banner-content {
        width: 100%;
        padding: 24px 15px;
        .wp-statistics {
          margin-bottom: 14px;
          .statistics-img {
            height: 100%;
          }
          .content {
            padding: 0 12px !important;
          }
          .statistics {
            font-size: 14px !important;
            .layer-statistics {
              font-size: 14px !important;
            }
          }
          .key {
            font-size: 12px !important;
            .layer-key {
              font-size: 12px !important;
            }
          }
        }
      }
      .banner-search {
        margin-top: 0px;
        margin-bottom: 0;
      }
    }
    .banner-slider-mobile {
      display: block;
      height: 100% !important;
      width: 100% !important;
      .home-banner-img {
        height: 250px;
        width: 100%;
        // min-height: 730px;
        // object-position: bottom !important;
        object-fit: cover !important;
      }
    }
  }
}

//aos css
[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}
