@import "../../const/settings.scss";
.wp-consultation {
  width: 100%;
  // background-color: purple;
  background-image: url("../../../public/image/consultation-background.png");
  background-size: cover;
  .consultation {
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 40px 0;
    gap: 45px;
    @include media(mld) {
      gap: 20px;
    }
    .introduce {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      flex: 1;
      @include media(mld_991) {
        display: none;
      }
      .logo {
        margin-bottom: 45px;
      }

      color: var(--Color-3, #fff);
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 133.333% */
    }
    .regist-form {
      padding: 30px;
      width: 100%;
      flex: 1;
      border-radius: 5px;
      background: var(--Color-3, #fff);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include media(mld) {
        margin-left: 25px;
        margin-right: 25px;
      }
      .title-mobile {
        color: var(--Color-1, #2a3467);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 12px;
        display: none;
        @include media(mld_991) {
          display: block;
        }
        @include media(sm) {
          font-size: 16px;
        }
      }
      .form-title {
        color: var(--Color-1, #2a3467);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 16px;
        font-style: italic;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 30px;
      }
      .ant-form-item-label {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .ant-input {
        height: 45px;
        background: #f5f5f5;
        border: 1px solid #2a3467;
        border-radius: 5px;
      }
      .wp-button-choose {
        height: 45px;
        background-color: #f5f5f5;
        border: 1px solid #2a3467;
        border-radius: 5px;
        padding: 4px 11px;
        .button-choose {
          height: 100%;
          display: flex;
          align-items: center;
        }
        .un-choose {
          color: rgba(0, 0, 0, 0.5);
          font-size: 14px;
          font-style: italic;
          font-weight: 400;
          line-height: normal;
        }
      }
      .ant-select-selector {
        height: 45px !important;
        background-color: #f5f5f5 !important;
        border: 1px solid #2a3467 !important;
        border-radius: 5px;
      }
      .ant-select-selection-placeholder {
        margin: auto;
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
      }
      .ant-select-dropdown{
        z-index: 1;
      }
      // .ant-select-selection-item {
      //   margin: auto;
      // }
      .ant-select-selection-search-input {
        height: 100%;
      }
    }
  }
  .form-note {
    color: #000;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
  .wrapper-button-submit {
    margin-bottom: 0;
    margin-top: 16px;
    @include media(mld) {
      margin-top: 15px;
    }
    .ant-form-item-control-input-content {
      text-align: center;
    }
    .ant-btn-primary {
      min-width: 164px;
      padding: unset;
      border-radius: 5px !important;
      background: #d41515 !important;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-color: #d41515 !important;
      span {
        font-size: 16px;
        font-weight: 600;
      }
    }
    @include media(md) {
      .ant-btn-primary {
        span {
          font-size: 14px !important;
        }
        height: 30px !important;
      }
    }
  }
  .success-form {
    padding: 30px;
    width: 100%;
    flex: 1;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url("../../../public/image/bg-success-form.webp") #fff;
    background-size: cover;
    min-height: 410px;
    @include media(mld) {
      margin-right: 20px;
    }
    @include media(mld_991) {
      margin-left: 25px;
      margin-right: 25px;
    }
    @include media(sm) {
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100vw - 40px);
    }

    .logo_doc {
      height: 116px;
      object-fit: contain;
    }
    .title-line-1 {
      margin-top: 9px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: var(--Color-1, #2a3467);
      text-align: center;
    }
    .title-line-3 {
      color: #000000;
    }
  }
  .title-success {
    color: var(--Color-1, #2a3467);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 45px;
  }
  .title-line-3 {
    text-align: center;
    margin-top: 44px;
  }
  .ant-col-xs-12,
  .ant-col-xs-24,
  .ant-col-24 {
    @include media(sm) {
      padding-left: 7px !important;
      padding-right: 7px !important;
    }
  }
}
