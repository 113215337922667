@import "../../src/const/settings.scss";
.wpMain {
  overflow: hidden;
  background: #fff;
  .hiddenH1 {
    position: absolute;
    top: 100px;
    z-index: -1;
  }
  .backgroundCTA {
    position: relative;
    height: 531px;
    width: 100%;
    margin-bottom: 80px;
    margin-top: 60px;

    .imageFirst {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
    }
    .imageSecond {
      display: none;
      width: 500px;
      height: 239px;
    }
    .content {
      left: 0;
      right: 0;
      text-align: center;
      margin: 66px 0;
      position: absolute;
      z-index: 2;
      .logoFirst {
        width: 209px;
        height: 110px;
        display: block;
        margin: auto;
      }
      .logoSecond {
        display: none;
      }
      .titleAbove {
        margin: 41px 0 11px 0;
        font-size: 30px;
        font-weight: 600;
        color: #ffffff;
        text-transform: capitalize;
      }
      .titleBelow {
        margin-bottom: 57px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
      }
      .btnCTA {
        margin: auto;
        width: 198px;
        height: 45px !important;
        border-radius: 5px;
        background: #d41515;
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        align-content: center;
      }
    }
  }
  .wpTag {
    max-width: 1200px;
    margin: 60px auto 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    border-radius: 5px;
    background: #f5f5f5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 25px 40px;
    .tagItem {
      display: flex;
      gap: 30px;
      width: 100%;
      a {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--Color-5, #293568) !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 156.25% */
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;
      }
    }
    @include media(lg) {
      gap: 20px;
      padding: 15px 20px;
      .tagItem {
        gap: 20px;
        a {
          font-size: 14px;
        }
      }
    }
    @include media(md) {
      background: none;
      box-shadow: none;
      align-items: start;
      padding: 15px;
      .tagItem {
        flex-direction: column;
        border-radius: 5px;
        background: #f5f5f5;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 15px 20px;
        gap: 12px;
        a {
          font-size: 14px;
        }
      }
    }
  }
}
@include media(md) {
  .wpMain {
    .backgroundCTA {
      height: 239px;
      .imageFirst {
        display: none;
      }
      .imageSecond {
        width: 100%;
        display: block;
        position: absolute;
      }
      .content {
        margin: 21px 0;
        .logoFirst {
          display: none;
        }
        .logoSecond {
          display: block;
          margin: auto;
          width: 103px;
          height: 54px;
        }
        .titleAbove {
          margin: 27px 0 12px 0;
          font-size: 12px;
        }
        .titleBelow {
          margin-bottom: 28px;
          font-size: 9px;
        }
        .btnCTA {
          font-size: 14px;
          width: 115px;
          height: 35px !important;
        }
      }
    }
  }
}
