@import "../../const/settings.scss";
.ant-popover {
  z-index: 9 !important;
}

.wrapper-form-search-reduce {
  max-width: 730px !important;
  width: 100%;
  padding: 9px !important;
  border-radius: 5px;
  // background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 162.96%);
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  .ant-select-selection-search-input {
    background-color: transparent;
    border: none;
  }
  .ant-select-selection-wrap {
    height: 100%;
  }
  .ant-select-selector {
    height: 56px !important;
    padding: 16px !important;
    border-radius: 8px !important;
    background: var(--color-white, #fff) !important;
    border: unset !important;
    // .ant-select-selection-search {
    //   // left: 16px;
    //   right: 16px;
    // }
  }

  .ant-form-item {
    margin: 0 12px 0 0;
    width: 100%;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    color: var(--color-text, #6884a7);
    font-size: 16px;
  }
  input {
    // height: 56px !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    color: var(--color-text, #6884a7);
  }
  .ant-select-selection-item {
    font-size: 16px !important;
  }
  .button-submit-search {
    width: 100%;
    height: 56px;
    border-radius: 5px;
    background: var(--color-brand-color, #00c5d7);
    cursor: pointer;
    border-width: 0 !important;
    span {
      color: #fff;

      /* P1/Bold */
      // font-family: Roboto Flex;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }
  .category-service-input {
    flex: 2;
  }
  .location-input {
    flex: 2;
  }
  .clinic-input {
    flex: 4.5;
  }
  .button-submit {
    flex: 1;
    .ant-form-item-control-input-content {
      display: flex;
    }
  }
  // .button-submit.location {
  .button-submit {
    // flex: 1.5;
    margin-right: 0;
    margin-bottom: 0;
  }
}

@include media(lg) {
  .wrapper-form-search-reduce {
    margin: 0 12px;
    max-width: none !important;
  }
}

@include media(mld) {
  .wrapper-form-search-reduce {
    margin: 0;
    padding: 0 15px;
    flex-direction: column;
    // .ant-form-item {
    //   margin: 0 0 12px 0 !important;
    // }
  }

  .clinic-input {
    margin-bottom: 0;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    width: 100%;
  }
  .button-submit {
    width: 100%;
  }

  .button-submit.location {
    margin-top: 10px;
  }
}
@include media(md) {
  .wrapper-form-search-reduce {
    .ant-select-selector {
      height: 45px !important;
      padding: 0 16px !important;
    }
    .ant-select-selection-search-input {
      // height: 45px !important;
      font-size: 14px !important;
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 14px !important;
    }
    .button-submit-search {
      height: 45px !important;
      span {
        font-size: 14px !important;
      }
    }
  }
}
